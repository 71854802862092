.mainWrapper{
    height: 9vh;
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #F9F9F9;
}

.logoWrapper{
    width: 20%;
    height: 9vh;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 1%;
}
.logoWrapper img{
    height: 6vh;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.rightSectionWrapper{
    display: flex;
    padding: 0px 1% 0px 0px;
}

.userIconWrapper{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    cursor: pointer;
}
.loginBtnWrapper{
    padding: 0px 0px 0px 12px;
}
.burgerIcon{
    display: none;
}

.sliderWrapper{
    display: none;
}
.headerWrapper{
    height: 8svh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.closeIcon{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navOptionsWrapper{
    padding: 0px 10%;
}

.sectionWrapper{
    padding: 0px 0px 20px 0px;
}
.sectionTitle{
    color: #AFAFAF;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px 0px;
}


.eachNavWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    align-items: center;
    cursor: pointer;
}
.eachNavWrapper:hover{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    align-items: center;
    cursor: pointer;
    color: #92C43D;
}

.eachNavIcon{
    display: flex;
    align-items: center;
}




@media only screen and (max-width: 600px) {
    .mainWrapper{
        position: relative;
    }
    .logoWrapper{
        display: none;
    }
    .burgerIcon{
        display: block;
        padding: 0px 2%;
    }
    .sliderWrapper{
        display: flex;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100svh;
        z-index: 99900;
    }
    .sliderContainer{
        width: 100%;
        position: relative;
        height: 100svh;
        background-color: #00000045;
    }
    .sliderContent{
        width: 75%;
        background-color: white;
        height: 100svh;
    }

}