.mainContainer{
    padding: 2%;
}

.mainContent{
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 15px;
}

.welcomeBrand{
    text-transform: capitalize;
}
.welcomeBrand h2{
    font-weight: 600;
    margin: 4px 0px 12px;
}
.firmName h4{
    font-weight: 600;
    color: #828282;
    margin: 2px 0px 2px;
}
.officeAddress{
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 500;
    color: #828282;
}


.aboutWrapper{
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 15px;
    margin: 16px 0px 0px;
    font-weight: 500;
    color: #828282;
    font-size: 0.8rem;
    text-align: justify;
}



.featuredWrapper{
    padding: 20px 0px;
}
.featuredTitle{
    text-align: center;
    padding: 25px 0px;
    font-weight: 600;
}
.cardsWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.eachCardWrapper{
    background-color: #dfdfdf63;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 18px;
    flex: 1;
}
.topTitle{
    font-size: 0.8rem;
    font-weight: 600;
    color: #92c43d;
    text-transform: uppercase;
    padding: 0px 0px 0px;
    height: 5svh;
    display: flex;
    align-items: flex-start;
}

.crispMsg{
    font-weight: 600;
    font-size: 1.2rem;

    height: 8svh;
    display: flex;
    align-items: center;
}
.bigMsg{
    padding: 10px 0px;
    color: #828282;
    font-weight: 400;
    height: 10svh;
}
.exploreBtnWrapper{
    padding: 10px 0px 0px;
}
.exploreBtn{
    background-color: #92c43d;
    width: 100%;
    border: none;
    padding: 14px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.comingSoonBtnWrapper{
    padding: 10px 0px 0px;
}
.coomingSoonBtn{
    background-color: #F26522;
    width: 100%;
    border: none;
    padding: 14px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.comingSoonTopTitle{
    font-size: 0.8rem;
    font-weight: 600;
    color: #F26522;
    text-transform: uppercase;
    padding: 0px 0px 20px;
}



.exploreCatalogueBtnWrapper{
    padding: 10px 0px 0px;
}
.exploreCatalogueBtn{
    background-color: #036241;
    width: 100%;
    border: none;
    padding: 14px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .mainContainer{
        padding: 4%;
    }
    .cardsWrapper{
        width: 100%;
    }
    .eachCardWrapper{
        flex: none;
        width: calc(100% - 36px);
    }

}