.mainWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 15%;
}

.sectionWrapper{
    padding: 0px 0px 20px 0px;
}
.sectionTitle{
    color: #AFAFAF;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px 0px;
}


.eachNavWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    align-items: center;
    cursor: pointer;
}
.eachNavWrapper:hover{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    align-items: center;
    cursor: pointer;
    color: #92C43D;
}

.eachNavIcon{
    display: flex;
    align-items: center;
}