.mainWrapper{
    padding: 2%;
}
.mainContainer{
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;

}


.userdataTag{
    padding: 16px 2%;
    display: flex;
}
.userdataTxt{
    padding: 5px 12px;
    background-color: #d9ff9830;
    color: #92C43D;
    font-weight: 500;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #92C43D;
    font-size: 0.8rem;
}
.searchWrapper{
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 0px;
}
.searchContainer{
    width: 96%;
    padding: 4px 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchBoxWrapper{
    width: 85%;
}
.searchBoxWrapper input{
    padding: 11px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.filterBoxWrapper{
    width: 15%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}
.filterIcon{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E5E5E5;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}


.tableHeader{
    padding: 10px 0px;
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #E5E5E5;
}

.tableBodyWrapper{
    height: 58svh;
    overflow-y: scroll;
}
.eachRecordWrapper{
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0px;
}

.sn{
    width: 4%;
    text-align: center;
    font-weight: 500;
}
.fullName{
    width: 25%;
    padding: 0px 10px;
    font-size: 0.8rem;
    font-weight: 500;
}
.phoneNumber{
    width: 12%;
    padding: 0px 10px;
}
.addedOn{
    width: 15%;
    padding: 0px 10px;
}
.action{
    width: 20%;
}