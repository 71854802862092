.mainWrapper{
    height: 86svh;
}
.osHeader{
    display: flex;
    align-items: center;
    height: 6svh;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    
}
.osTxt{
    display: flex;
    text-align: center;
    justify-content: center;
    color: #92c43d;
    font-weight: 500;
}
.trackOrderBtn a{
    padding: 8px 10px;
    background-color: #92c43d;
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.statsWrapper{
    height: calc(80svh - 20px);
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 2%;
    gap: 15px;
    justify-content: space-between;
}

.card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 20svh;
    width: 32%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    
}

.cardGraph{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 40svh;
    width: 32%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    position: relative;
}
.graphWrapper{
    height: 40svh;
    width: 400px;
}
.cardTitle{
    padding: 14px;
}
.cardValue{
    padding: 5px 14px;
    font-size: 1.8rem;
    font-weight: 600;
}
.cardPie{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 40svh;
    width: 66%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    position: relative;
}
.cardGraphTxt{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 20svh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.cardTitlePercentage{
    font-size: 2rem;
    font-weight: 600;
}
.cartTitleTxt{
    font-size: 0.8rem;
    font-weight: 500;
    color: #a5a5a5;
}



@media only screen and (max-width: 600px) {
    .card{
        width: 100%;
        height: 14svh;
    }
    .cardGraph{
        width: 100%;
    }
    .cardPie{
        width: 100%;
    }
    .graphWrapper{
        height: 34svh;
        width: 100%;
    }

}