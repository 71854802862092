.mainWrapper {
    background: #080808;
    height: 100svh;
    color: #dfdfdf;
}

.pageTitle {
    height: 14svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.pageTitle h2 {
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-weight: 600;
}

.currentStatusWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

.currentStatus {
    padding: 5px 18px;
    border: 1px solid #9925a7;
    background-color: #9925a740;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.trackWrapperWeb{
    flex: 1;
    display: flex;
    padding: 30px 0px ;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: center;
    height: 70svh;
    background-color: #323232;
    align-items: center;
}
.trackWrapperMob{
    display: none;
    background-color: #323232;
}
.eachStepWrapper {
    width: 240px;
    display: flex;
    flex-direction: column;
}

.eventTimeTxtWrapper {
    display: block;
}

.eventTimeTxtWrapperMob {
    display: none;
}
.message{
    text-align: center;
    padding: 6px 0px;
    font-weight: 600;
}
.eventTime {
    text-align: center;
    font-size: 0.6rem;
    font-weight: 500;
}

.connectorWrapper {
    display: flex;
    align-items: center;
    padding: 6px 0px;
}

.connectingLine {
    height: 5px;
    background-color: #9925a7;
    width: 103px;
}
.connectingLineDull {
    height: 5px;
    background-color: #c9c9c9;
    width: 103px;
}

.connectingLineTrans {
    height: 5px;
    background-color: transparent;
    width: 103px;
}

.centerDot {
    height: 34px;
    width: 34px;
    background-color: #9925a7;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerDotDull {
    height: 34px;
    width: 34px;
    background-color: #c9c9c9;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomMessage {
    padding: 0px 5px;
}

.location {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
    height: 40px;
}

.loadingWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100svh;
    width: 100%;
    flex-direction: column;
    background-color: white;
}
.loadingTxtWrapper{
    height: 40svh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
.pw{
    font-size: 1.4rem;
    font-weight: 500;
    padding: 10px 0px;
}
.cyd{
    font-size: 1rem;
}
.loadingContainer{
    width: 100%;
    height: 60svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadingContainer img{
    width: 20%;
    object-fit: contain;
}
.webIcon{
    display: block;
}
.mobIcon{
    display: none;
}
.footer{
    background-color: #080808;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    color: #c9c9c9;
    font-weight: 500;
    height: 8svh;
}
.footer a{
    color: #c9c9c9;
    text-decoration: none;
}


@media only screen and (max-width: 600px) {

    .loadingWrapper{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100svh;
        width: 100%;
        background-color: #F9F9F9;
    }
    .loadingContainer{
        width: 100%;
        height: 60svh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .loadingContainer img{
        width: 100%;
        object-fit: contain;
    }
    .currentStatus{
        font-size: 0.8rem;
    }

    .pageTitle{
        height: 8svh;
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .pageTitle h2{
        font-size: 0.9rem;
    }
    .trackWrapperWeb{
        display: none
    }
    .trackWrapperMob {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 84svh;
        overflow-y: scroll;
    }

    .eachStepWrapper {
        flex-direction: row;
        width: 100%;
    }

    .connectorWrapper {
        flex-direction: column;
        width: 25%;
        padding: 0px 0px;
    }

    .bottomMessage {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .connectingLine {
        width: 5px;
        height: 43px;
    }
    .connectingLineDull{
        width: 5px;
        height: 43px;
        background-color: #c9c9c9;
    }

    .connectingLineTrans {
        width: 5px;
        height: 53px;
    }

    .eventTimeTxtWrapper {
        display: none;
    }

    .eventTimeTxtWrapperMob {
        display: block;
    }
    .eventTime{
        text-align: left;
    }
    .webIcon{
        display: none;
    }
    .mobIcon{
        display: block;
    }
    .location{
        padding: 6px 0px;
    }
    .message{
        text-align: center;
        padding: 0px;
        font-weight: 600;
        font-size: 1rem;
    }


    .thatsAll{
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        color: #c9c9c9bf;
        padding: 0px 0px 20px;
    }

    .footer{
        height: 8svh;
    }
}