.topWrapper{
    display: flex;
    justify-content: space-between;
    height: 6svh;
    align-items: center;
    /* border-bottom: 1px solid #9925a7; */
}
.productTitle{
    font-weight: 600;
    font-size: 0.9rem;
}
.righttw{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 2%;
}
.productsWrapper{
    height: 80svh;
}
.productsContainer{
    height: 66svh;
    width: 96%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 20px;
    overflow-y: scroll;
    padding: 2svh 2%;
}
.wishlist{
    font-size: 0.8rem;
    font-weight: 500;
    color: #818181;
}
.filterIcon{
    cursor: pointer;
}
.filterIcon button{
    padding: 8px 10px;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

@media only screen and (max-width: 600px) {
    .topWrapper{
        padding: 0px 2%;
        display: flex;
        align-items: center;
    }
    .productsContainer{
        justify-content: space-evenly;
        height: 76svh;
    }
}