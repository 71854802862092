.mainWrapper {
    padding: 0px 3% 0px 0px;
}

.mainHeaderWrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 0px;
}

.bigTxt {
    font-size: 1.2rem;
    font-weight: 500;
    color: #4E4E4E;
}

.smallTxt {
    font-size: 0.7rem;
    font-weight: 400;
    color: #bbbbbb;
    padding: 8px 0px;
}

.statsTxt {
    color: #92C43D;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 16px 10px;
}


.tilesWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
    row-gap: 10px;
}

.eachCardWrapper {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    width: 24%;

}

.eachCardContainer {
    padding: 25px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardTitle {
    font-weight: 500;
    color: #4E4E4E;
    font-size: 0.8rem;
}
.cardDefinition{
    font-size: 0.6rem;
    color: #7C7C7C;
}
.cardIcon {
    font-weight: 600;
    color: #92C43D;
}

.valueTxt {
    font-size: 1.5rem;
    color: #92C43D;
    font-weight: 500;
    padding-top: 30px;
}



.tableHeader {
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 12px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #7C7C7C;
}

.rowsWrapper {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 12px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.eachRowWrapper {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    height: 5vh;
    align-items: center;
    color: #7C7C7C;
    font-size: 0.8rem;
    font-weight: 500;
}

.eachRowWrapper:last-child {
    border-bottom: none;
}


.sn {
    width: 3%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.qr {
    width: 10%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.item {
    width: 15%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.points {
    width: 5%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.username {
    width: 10%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}












/* Main Wrapper */
.mainWrapper {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

/* Header */
.mainHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.bigTxt {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.smallTxt {
    font-size: 14px;
    color: #777;
}

select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Graph Section */
.graphWrapper {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}
.graphWrapperCity {
    display: flex;
    gap: 20px;
}

.graphContainer {
    flex: 1;
    height: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Loading and Empty States */
.loading {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #555;
}

.emptyMessage {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #888;
}


.recentTransactionsWrapper{
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.headerWrapper{
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    color: #888;
    height: 5svh;
    align-items: center;
    background-color: #dfdfdf70;
}
.transactionCard{
    display: flex;
    font-size: 0.8rem;
    font-weight: 500;
    color: #888;
    height: 4svh;
    align-items: center;
}
.transactionCard:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
}
.sn{
    text-align: center;
}
.transactionTitle{
    width: 43%;
}
.productsTotal{
    width: 10%;
}
.addressCity{
    width: 12%;
}
.paymentMethod{
    width: 8%;
}
.totalPointsEarned{
    width: 8%;
}