.mainWrapper {
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 24;
}

.mainContainer {
    height: 100svh;
    width: 100%;
    position: relative;
    background-color: #00000050;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.mainContent {
    height: 40svh;
    background-color: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.titleWrapper{
    height: 7svh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

}
.filterWrapper{
    padding: 0px 5%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    text-align: justify;
}
.activeStatus{
    padding: 8px 10px;
    background-color: #92C43D;
    color: white;
    font-weight: 500;
    border: 1px solid #92C43D;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    cursor: pointer;
}
.inactiveStatus{
    padding: 8px 10px;
    background-color: #dfdfdf;
    font-weight: 500;
    border: 1px solid #92C43D;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.7rem;
    cursor: pointer;
}



.close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0px;

}
.close button{
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 0.8rem;
    color: #a5a5a5;
}