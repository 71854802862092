.mainWrapper {
    padding: 0px 3%;
}

.topWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.statsTxt {
    color: #92C43D;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 16px 0px;
}

.searchInput input {
    padding: 10px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 300px;
    font-size: 0.8rem;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 12px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #7C7C7C;
}

.rowsWrapper {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding: 12px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    height: 65svh;
    overflow-y: scroll;
}

.eachRowWrapper {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    height: 5vh;
    align-items: center;
    color: #7C7C7C;
    font-size: 0.8rem;
    font-weight: 500;
}

.eachRowWrapper:last-child {
    border-bottom: none;
}


.sn {
    width: 3%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.qr {
    width: 16%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.fullname {
    width: 16%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.item {
    width: 15%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.phoneNumber {
    width: 15%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.points {
    width: 25%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}
.itemQuantity{
    width: 20%;
}
.placeOfSupply {
    width: 20%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.address {
    width: 25%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}

.username {
    width: 10%;
    text-align: left;
    padding: 0px 0px 0px 4px;
}
.time{
    width: 15%;
}
.time a {
    border: 1px solid #92C43D;
    padding: 6px 14px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #92C43D;
    font-size: 0.7rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
}
.posTitle{
    display: none;
}

@media only screen and (max-width: 600px) {
    .mainWrapper{
        padding: 0px 1%;
    }
    .topWrapper{
        height: 9svh;
        padding: 0px 10px;
        align-items: center;
        display: flex;
        border-bottom: 1px solid #dfdfdf;
        gap: 10px;
    }
    .searchInput {
        width: 80%;
    }
    .topWrapper input{
        width: calc(100% - 22px);
    }
    .tableWrapper{
        height: 79svh;

    }
    .tableHeader{
        display: none;
    }
    .rowsWrapper {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: transparent;
        padding: 10px 8px;
        height: calc(79svh - 20px);
    }

    .eachRowWrapper {
        height: auto;
        flex-wrap: wrap;
        padding: 10px 10px 14px;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
}
    .sn{
        height: 35px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #92C43D;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        background-color: #92C43D34;
        color: #92C43D;
        font-weight: 600;
}
    .fullname {
        width: 55%;
        text-align: left;
        padding: 0px 0px 0px 4px;
        font-size: 1rem;
        font-weight: 600;
    }

    .address {
        width: 100%;
    }

    .phoneNumber {
        width: 30%;
        text-align: right;
        padding: 0px 0px 0px 4px;
    }

    .time {
        padding: 5px 0px 0px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    .time a {
        padding: 10px;
    }
    .itemQuantity{
        width: 100%;
        display: flex;
        gap: 10px;
        padding: 6px 6px;
    }
    .placeOfSupply{
        width: 100%;
        display: flex;
        gap: 10px;
    }
    .posTitle{
        display: block;
        font-weight: 500;
    }
}