.mainWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80svh;
}
.eachCardWrapper{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 24svh;
    width: 30%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}