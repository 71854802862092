.mainWrapper{
    min-height: 100vh;
}
.mainContainer{
    padding: 0px 2%;
}
.urHeaderRow{
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}

.eachRowTitle{
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 0px;
}
.addNewBtn button{
    padding: 4px 12px;
    border: 1.4px solid #92C43D;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
    color: #92C43D;
    font-weight: 500;
    cursor: pointer;
}
.addNewBtn button:hover{
    color: white;
    background-color: #92C43D;
    cursor: pointer;
}

.addNowBtn button{
    padding: 10px 12px;
    border: 1.4px solid #92C43D;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: white;
    color: #92C43D;
    font-weight: 500;
    cursor: pointer;
    min-width: 100px;
}
.addNowBtn button:hover{
    color: white;
    background-color: #92C43D;
    cursor: pointer;
}



.urContentWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 0px 20px;
    margin: 0px 0px 20px;
}
.lrContentWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 0px 20px;
    margin: 0px 0px 20px;
}
.createNewGstWrapper{
    width: 100%;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    padding: 14px;
}
.twoInRow{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.twoInRow .eachFieldWrapper{
    width: 40%;
}
.eachGstCardWrapper{
    padding: 14px;
    border: 1px solid #dfdfdf;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: white;
    width: 280px;
}


.eachFieldWrapper{
    padding: 0px 0px 20px 0px;
}
.eachFieldTitle{
    font-size: 0.8rem;
    font-weight: 500;
    padding: 4px 0px;
}
.eachFieldInput input{
    padding: 12px 12px;
    width: calc(100% - 26px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.gstNumber{
    text-transform: uppercase;
}
.gstNumber::placeholder{
    text-transform: capitalize;
}
.eachFieldInput select{
    padding: 12px 12px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.eachFieldInput textarea{
    padding: 12px 12px;
    width: calc(100% - 26px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.tagWrapper{
    width: 100%;
    display: flex;
    padding: 4px 0px 12px;
}
.tag{
    font-weight: 500;
    font-size: 0.7rem;
    border: 1px solid #92C43D;
    background-color: #92C43D;
    color: white;
    padding: 2px 8px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}

.numberWrapper{
    padding: 12px 0px 6px;
    font-weight: 500;
}
.addressWrapper{
    font-weight: 500;
    font-size: 0.8rem;
    color: #828282;
}