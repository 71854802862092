/* General container */
.container {
    background-color: #f4f4f4;
    min-height: 85svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Title styling */
.title {
    font-size: 2.5rem;
    color: #333;
    margin: 20px 0px 60px;
    text-align: center;
}

/* Form container */
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px 5%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: all 0.3s ease;
}

/* Input field and label */
.label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
    font-weight: 500;
}

.inputField {
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
    outline: none;
}

.inputField:focus {
    border-color: #009688;
}

/* Button styling */
.submitButton {
    background-color: #009688;
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #00796b;
}

/* Dialog overlay */
.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dialog box */
.dialogBox {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 80%;
    max-width: 400px;
    animation: fadeIn 0.5s ease-out;
}

.dialogBox h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.dialogActions {
    display: flex;
    justify-content: space-around;
}

.confirmButton,
.cancelButton {
    padding: 10px 20px;
    border: none;
    font-size: 0.8rem;
    border-radius: 5px;
    cursor: pointer;
}

.confirmButton {
    background-color: #92C43D;
    color: white;
}

.confirmButton:hover {
    background-color: #00796b;
}

.cancelButton {
    background-color: #f44336;
    color: white;
}

.cancelButton:hover {
    background-color: #e53935;
}

/* Add keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



.confirmTitle{
    padding: 0px 0px 20px;
    font-weight: 500;
}
.shopName{
    font-size: 1rem;
    padding: 0px 0px 20px;
    font-weight: 600;
}