.eachItemWrapper{
    width: 23%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    overflow: hidden;
    height: 36svh;
    border: 1px solid transparent;
}
.eachItemWrapper:hover{
    border: 1px solid #9925a760;
}
.thumbnail{
    position: relative;
}
.thumbnail img{
    height: 24svh;
    width: 100%;
    object-fit: cover;
}

.wishlistIcon{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    height: 34px;
    width: 34px;
    border: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    cursor: pointer;
}
.wishlistIconAdded{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    height: 34px;
    width: 34px;
    border: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    cursor: pointer;
    background-color: #fff;
}

.title{
    padding: 5px 4% 2px;
    font-weight: 500;
    font-size: 1rem;
}
.brand{
    padding: 0px 4%;
    font-weight: 500;
    font-size: 0.8rem;
    color: #818181;
}
.categoryWrapper{
    padding: 6px 4%;
    display: flex;
}
.category{
    border: 1px solid #dfdfdf;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 4px 10px;
    font-size: 0.7rem;
    background-color: #dfdfdf;
    font-weight: 500;
    text-transform: capitalize;
}


@media only screen and (max-width: 600px) {
    .eachItemWrapper{
        width: 46%;
    }
    .title{
        font-size: 0.8rem;
    }
}