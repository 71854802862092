/* Global Styles */
.pageContainer {
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
    box-sizing: border-box;
}

.header {
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 20px;
    background-color: #92C43D;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pageTitle {
    font-size: 2rem;
    margin-bottom: 10px;
    animation: fadeIn 2s ease-out;
    font-weight: 500;
}

.subTitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-out;
}

.ctaButton {
    background-color: #ff5722;
    color: white;
    padding: 12px 30px;
    border: none;
    font-size: 0.8rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    display: inline-block;
    margin-top: 20px;
    font-weight: 500;
}
.ctaButton a{   
    color: white;
}

.ctaButton:hover {
    background-color: #e64a19;
}

/* About Section */
.aboutContainer {
    text-align: center;
    padding: 60px 20px;
    background-color: #fafafa;
    border-radius: 8px;
    margin-bottom: 60px;
}

.sectionTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.aboutText {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #666;
    line-height: 1.6;
}

.benefitsContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.benefitCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
}

.icon {
    font-size: 3rem;
    color: #009688;
    margin-bottom: 20px;
}

.benefitCard h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.benefitCard p {
    font-size: 1rem;
    color: #555;
}

/* Shop Section */
.shopContainer {
    text-align: center;
    margin-bottom: 60px;
}

.shopGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
}

.shopCard {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.shopCard:hover {
    transform: translateY(-10px);
}

.shopImage img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.shopDetails {
    padding: 20px 20px 40px;
}

.shopName {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
}

.shopDescription {
    color: #666;
    font-size: 1rem;
    margin-bottom: 20px;
}

.shopButton {
    /* background-color: #009688; */
    background-color: #92C43D;
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    -webkit-transition: background-color 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out;
    -ms-transition: background-color 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out;
}

.shopButton:hover {
    background-color: #00796b;
}

.noShops {
    font-size: 1.2rem;
    color: #777;
    padding: 20px;
}
