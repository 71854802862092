.mainWrapper {
    padding: 0px 2%;
    border-top: 1px solid #dfdfdf;
    height: 5svh;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    height: 5svh;
    align-items: center;
}

.copyrightTxt {
    color: #BBBBBB;
    font-size: 0.8rem;
    font-weight: 500;
}

.visitQnnox a {
    color: #92C43D;
    font-size: 0.8rem;
    font-weight: 500;
}



@media only screen and (max-width: 600px) {
    .mainContainer{
        flex-direction: column;
        font-size: 0.6rem;
        justify-content: space-around;
    }
    .copyrightTxt{
        font-size: 0.6rem;
    }
    .visitQnnox a{
        font-size: 0.6rem;
    }    

}