.mainWrapper{
    background-color: #F9F9F9;
    height: 100svh;
    width: 100%;
}
.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    width: 100%;
}
.formWrapper{
    height: 50vh;
    width: 35%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background-color: white;
}

.formContainer{
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(50vh - 20%);
    width: 80%;
    gap: 20px;
}
.usernameWrapper{
    width: 100%;
}

.usernameWrapper input{
    padding: 12px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.nextBtnWrapper{
    padding: 20px 0px;
}
.nextBtnWrapper button{
    padding: 12px 20px;
    width: 200px;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #92bf39;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 1.2px;
    color: #92bf39;
}
.nextBtnWrapper button:hover{
    background-color: #92bf39;
    color: white;
    
}

.password{
    width: 100%;
    padding: 0px 0px;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.password input{
    padding: 12px 10px;
    width: calc(100% - 22px);
    border: 1px solid transparent;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.passwordField{
    width: 90%;
}
.passwordVisibility{
    width: 10%;
    display: flex;
    justify-content: center;
}


.actionBtnWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.cancelBtnWrapper{
    width: 46%;
}
.cancelBtnWrapper button{
    padding: 12px 0px;
    width: 100%;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #92bf39;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 1.2px;
    color: #92bf39;   
}
.cancelBtnWrapper button:hover{
    background-color: rgb(88, 15, 15);
    color: white;
    border: 1px solid rgb(88, 15, 15);
}
.loginBtnWrapper{
    width: 46%;
}

.loginBtnWrapper button{
    padding: 12px 20px;
    width: 100%;
    text-transform: uppercase;
    background-color: #92bf39;
    border: 1px solid #92bf39;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 1.2px;
    color: white;   
}

.loginBtnWrapper button:hover{
    background-color: #92bf39;
    color: white;
}


@media only screen and (max-width: 600px) {
    .formWrapper{
        width: 80%;
    }


}