.mainWrapper{
    background-color: #F9F9F9;
    height: 100svh;
}
.mainBodyWrapper{
    display: flex;
    width: 100%;
    padding-top: 9svh;
    height: 86svh;
}

.mainLeftWrapper{
    width: 20%;
}

.mainRightWrapper{
    width: 80%;
    height: 86svh;
    overflow-y: scroll;
}


@media only screen and (max-width: 600px) {
    .mainBodyWrapper{
        padding-top: 0px;
    }
    .mainLeftWrapper{
        width: 0%;
        display: none;
    }
    .mainRightWrapper{
        width: 100%;
    }

}